import { inject } from "@angular/core";
import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { tap, first, finalize } from "rxjs";
import { Exchange } from "../models/exchange.model";
import { SharedActions } from "../state/shared-action-types";

export const exchangesResolver: ResolveFn<Exchange> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  let loading = false;
  const store = inject(Store);
  return store.pipe(
    tap(() => {
      if (!loading) {
        loading = true;
        store.dispatch(SharedActions.loadAllExchanges());
      }
    }),
    first(),
    finalize(() => (loading = false))
  );
};
